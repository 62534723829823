import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import Button from '../atoms/TheButton'
const styles = require('scss/contactForm.module.scss')

const date = new Date()

const startYear = 1950
const endYear = date.getFullYear()

const ContactForm = () => {
  return (
    <div className={styles.contactForm}>
      <form
        action="/contact-done"
        name="jobContact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="form-name"
          value="jobContact"
        />
        <input type="hidden" name="bot-field" />
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              氏名 <abbr title="required">＊</abbr>
            </span>
            <input
              type="text"
              id="name"
              name="name"
              minLength={2}
              maxLength={20}
              autoComplete="name"
              required
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              氏名 (ふりがな)
            </span>
            <input
              type="text"
              id="kana"
              name="kana"
              minLength={0}
              maxLength={40}
              autoComplete="kana"
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <span className={styles.label}>
            生年月日
            <abbr title="required">＊</abbr>
          </span>
          <div className={styles.birthdayWrapper}>
            <label>
              <select
                className={styles.birthday}
                name="birthday_year[]"
              >
                <option></option>
                {Array(endYear - startYear + 1)
                  .fill(null)
                  .map((_, index) => {
                    return (
                      <option
                        key={`year-${index}`}
                        value={index + startYear}
                      >
                        {index + startYear}
                      </option>
                    )
                  })}
              </select>
              <span className={styles.birthdayLabel}>
                年
              </span>
            </label>
            <label>
              <select
                className={styles.birthday}
                name="birthday_month[]"
              >
                <option></option>
                {Array(12)
                  .fill(null)
                  .map((_, index) => {
                    return (
                      <option
                        key={`month-${index}`}
                        value={index + 1}
                      >
                        {index + 1}
                      </option>
                    )
                  })}
              </select>
              <span className={styles.birthdayLabel}>
                月
              </span>
            </label>
            <label>
              <select
                className={styles.birthday}
                name="birthday_day[]"
              >
                <option></option>
                {Array(31)
                  .fill(null)
                  .map((_, index) => {
                    return (
                      <option
                        key={`day-${index}`}
                        value={index + 1}
                      >
                        {index + 1}
                      </option>
                    )
                  })}
              </select>
              <span className={styles.birthdayLabel}>
                日
              </span>
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <span className={styles.label}>性別</span>
          <label>
            <input
              type="radio"
              id="man"
              name="gender"
              value="男性"
            />
            男性
          </label>
          <label className={styles.woman}>
            <input
              type="radio"
              id="woman"
              name="gender"
              value="女性"
            />
            女性
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              住所
              <abbr title="required">＊</abbr>
            </span>
            <input
              type="text"
              id="address"
              name="address"
              minLength={2}
              maxLength={200}
              autoComplete="address"
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              電話番号
              <abbr title="required">＊</abbr>
            </span>
            <input
              type="text"
              id="tel"
              name="tel"
              minLength={10}
              maxLength={15}
              autoComplete="tel"
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              メールアドレス
              <abbr title="required">＊</abbr>
            </span>
            <input
              type="text"
              id="email"
              name="email"
              minLength={6}
              maxLength={200}
              autoComplete="email"
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.label}>
              希望職種
              <abbr title="required">＊</abbr>
            </span>
            <select name="desiredJob">
              <option></option>
              <option value="フロントエンドエンジニア">
                フロントエンドエンジニア
              </option>
              <option value="バックエンドエンジニア">
                バックエンドエンジニア
              </option>
            </select>
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.remarksLabel}>
            <span>お問い合わせ内容</span>
            <textarea
              name="remarks"
              id="remarks"
              cols={20}
              rows={10}
              placeholder="（例）応募したい、業務内容について詳しく知りたい、採用担当者と電話でお話したい、など"
            ></textarea>
          </label>
        </div>
        <div className={styles.action}>
          <PC>
            <Button type="square" submit>
              送信
            </Button>
          </PC>
          <SP>
            <Button type="square" submit size="responsive">
              送信
            </Button>
          </SP>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
