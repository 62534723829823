import * as React from 'react'
import axios from 'axios'
import { navigate } from '@reach/router'

import { SP, PC } from '../../components/utils/media-query'
import Header from '../../components/organisms/Header'
import TitleWithText from '../../components/atoms/TitleWithText'
import ContactForm from '../../components/molecules/ContactForm'
import HeadingIllust from '../../components/atoms/HeadingIlust'
const styles = require('scss/jobDescription.module.scss')
import { RecruitAPIData } from './index'

type RecruitPageProp = {
  params: { id: string }
}
const JobDescriptionPage: React.FC<RecruitPageProp> = ({
  params,
}: RecruitPageProp) => {
  const [recruitData, setRecruitData] = React.useState(
    {} as RecruitAPIData,
  )
  const url = `https://nichicoma.microcms.io/api/v1/recruit/${params.id}`
  React.useEffect(() => {
    axios({
      url: `https://nichicoma.microcms.io/api/v1/recruit/${params.id}`,
      method: 'get',
      headers: {
        'X-API-KEY': '10c03845-077d-43fd-acbe-044df6d8fe4f',
      },
    })
      .then((res) => {
        const result = res.data
        setRecruitData(res.data)
      })
      .catch(() => {
        navigate('/recruit/')
      })
  }, [])
  const title = `${recruitData.job}の採用情報`
  const pageName = recruitData.jobEn
  return (
    <section className={styles.jobDescription}>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            title={title}
            pageName={pageName}
          />
        </SP>
        <PC>
          <HeadingIllust
            title={title}
            pageName={pageName}
          />
        </PC>
      </div>
      <div className={styles.contentsWrapper}>
        <div className={styles.head}>
          <TitleWithText
            titleNode={recruitData.aboutTitle}
            textNode={recruitData.about}
          />
          <h3>募集要項</h3>
          <span />
        </div>
        <div className={styles.requirements}>
          <ul>
            <li className={styles.firstChild}>
              <h5>職種名</h5>
              <SP>
                <p>{`${recruitData.job}`}</p>
              </SP>
              <PC>
                <p>{`${recruitData.job}`}</p>
              </PC>
            </li>
            <li>
              <h5>募集内容</h5>
              <div>
                <SP>
                  <p>{recruitData.jobDescription}</p>
                </SP>
                <PC>
                  <p>{recruitData.jobDescription}</p>
                </PC>
              </div>
            </li>
            <li>
              <h5>応募資格</h5>
              <SP>
                <p>{recruitData.jobCondition}</p>{' '}
              </SP>
              <PC>
                <p>{recruitData.jobCondition}</p>
              </PC>
            </li>
          </ul>
        </div>
        <div className={styles.form}>
          <div className={styles.formHead}>
            <SP>
              <TitleWithText
                titleNode={`応募フォーム`}
                textNode={`「採用情報について詳しく聞きたい」「話だけ聞いてみたい」など、カジュアルなご応募でも歓迎します。`}
              />
            </SP>
            <PC>
              <TitleWithText
                titleNode={`応募フォーム`}
                textNode={`「採用情報について詳しく聞きたい」「話だけ聞いてみたい」など、カジュアルなご応募でも歓迎します。`}
              />
            </PC>
            <span />
            <p>※印の欄は入力必須項目となります。</p>
          </div>
          <div className={styles.formBody}>
            <ContactForm />
          </div>
        </div>
        <div className={styles.contact}>
          <TitleWithText
            titleNode={`お問い合わせ`}
            textNode={`各種ご相談やご質問など、お気軽に問い合わせください。`}
          />
          <div className={styles.mail}>
            <a href="mailto:info@nichicoma.co.jp">
              info@nichicoma.co.jp
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
export default JobDescriptionPage
